
import { Component, Mixins } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import userImage from "@/components/general/user-image.vue";
import { HasFormErrors } from "@/mixins/has-form-errors";

// Import types
import { ActivityAccount, IndexActivityAccountPayload, CreateActivityAccountPayload, TeamFunctions } from "@/store/modules/activity-account.store";
import type { IndexAccountPayload } from "@/store/modules/account.store";

@Component({
    components: {
        userImage,
    },
})
export default class DossierTabPartContactMembers extends Mixins(HasFormErrors) {
    @Getter("activity/viewing") activity!: Activity;
    @Getter("auth/me") account!: CurrentAccount;
    @Action("account/indexForCurrentOffice") indexAccounts!: (payload: IndexAccountPayload) => Promise<Account[]>;
    @Action("activity-account/index") indexActivityAccount!: (payload: IndexActivityAccountPayload) => Promise<ActivityAccount[]>;
    @Action("activity-account/create") createActivityAccount!: (payload: CreateActivityAccountPayload) => Promise<ActivityAccount>;
    @Action("activity-account/delete") deleteActivityAccount!: (id: number) => Promise<ActivityAccount>;

    loading = false;

    teamMembers: Account[] = [];

    activityAccounts: ActivityAccount[] = [];

    get selfAccountActivity(): ActivityAccount | undefined {
        return this.activityAccounts.find((activityAccount) => Number(activityAccount.account_id) === Number(this.account.id));
    }

    async mounted() {
        this.loading = true;

        this.activityAccounts = await this.indexActivityAccount({ q: { and: { activity_id: this.activity.id } } });
        if (this.activityAccounts.length) {
            this.teamMembers = await this.indexAccounts({ q: { or: { id: this.activityAccounts.map((aa) => aa.account_id) } } });
        }

        this.loading = false;
    }

    async handleJoinTeam() {
        try {
            await this.createActivityAccount({ type: TeamFunctions.buyer, activity_id: Number(this.activity.id), account_id: Number(this.account.id) });

            this.$toast.open({ message: this.$t("views.contacts.team_joined_success") as string, type: "success", position: 'bottom-left' });
        } catch (e) {
            this.errorResponse = this.formatErrors(e);
        }
    }

    async handleLeaveTeam(id: number) {
        try {
            await this.deleteActivityAccount(id);

            this.$toast.open({ message: this.$t("views.contacts.team_left_success") as string, type: "success", position: 'bottom-left' });
        } catch (e) {
            this.errorResponse = this.formatErrors(e);
        }
    }
}
